import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/assistances/`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getAssistances(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// saving
async function saveAssistance(_zone) {
  return axios.post(baseURL, _zone).then(HandleResponse).catch(HandleError);
}

// editing
async function editAssistance(_zone) {
  return axios
    .patch(`${baseURL}${_zone._id}`, _zone)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteAssistance(id) {
  return axios
    .delete(`${baseURL}${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const assistanceApi = {
  getAssistances,
  saveAssistance,
  editAssistance,
  deleteAssistance,
};

export default assistanceApi;
