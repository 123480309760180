export async function HandleResponse(response) {
    if (response.status === 200 || response.status === 201) {
        return response.data;
    }

    if (response.status === 400) {
        // So, a server-side validation error occurred.
        // Server side validation returns a string error message, so parse as text instead of json.
        const error = await response.text();
        throw new Error(error);
    }
    throw new Error('Network response was not ok.');
}
// In a real app, would likely call an error logging service.
export function HandleError(error) {
    // debugger
    console.log('Throw error: ', error);
    throw error;
}
