import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/provinces/`;
// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getProvinces(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// saving
async function saveProvince(_province) {
  console.log("saving : ", _province);
  return axios.post(baseURL, _province).then(HandleResponse).catch(HandleError);
}

// editing
async function editProvince(_province) {
  return axios
    .patch(`${baseURL}${_province._id}`, _province)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteProvince(id) {
  return axios
    .delete(`${baseURL}${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const provinceApi = {
  getProvinces,
  saveProvince,
  editProvince,
  deleteProvince,
};

export default provinceApi;
