// require('dotenv').config();
import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/auth`;
// const baseURL = "https://gestion-alerte-api.onrender.com/api/v1/auth";

async function login(data) {
  return axios
    .post(`${baseURL}/login`, data, { withCredentials: false })
    .then(HandleResponse)
    .catch(HandleError);
}
async function signup(val) {
  const data = { username: val.username, password: val.password };
  return axios
    .post(`${baseURL}/register`, data)
    .then(HandleResponse)
    .catch(HandleError);
}
async function logout() {
  return axios.get(`${baseURL}/logout`).then(HandleResponse).catch(HandleError);
}

const authService = {
  login,
  logout,
  signup,
};

export default authService;
