import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/communes/`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getCommunes(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// saving
async function saveCommune(_commune) {
  return axios.post(baseURL, _commune).then(HandleResponse).catch(HandleError);
}

// editing
async function editCommune(_commune) {
  return axios
    .patch(`${baseURL}${_commune._id}`, _commune)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteCommune(id) {
  return axios
    .delete(`${baseURL}${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const communeApi = {
  getCommunes,
  saveCommune,
  editCommune,
  deleteCommune,
};

export default communeApi;
