/*
    # Importing all services
*/

import authentication from "./AuthService";
// ================================================================
import province from "./provinceService";
import commune from "./communeService";
import zone from "./zoneService";
// =================================================================
import alert from "./alertService";
import typeAlert from "./typeAlertService";
import assistance from "./assistanceService";
import user from "./userService";
import statique from "./staticService";
import client from "./clientService";
import charts from "./chartsServices";

/*

    # Exporting all services

*/

export const zoneAPI = zone;
export const alertAPI = alert;
export const communeAPI = commune;
export const provinceAPI = province;
export const authAPI = authentication;
export const typeAlertAPI = typeAlert;
export const assistanceAPI = assistance;
export const userAPI = user;
export const clientAPI = client;
export const staticAPI = statique;
export const chartsAPI = charts;
