// import logo from './logo.svg';
import "./App.css";
import { JWTProvider } from "./contexts/JWTContext";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";

import Locales from "./ui-component/Locales";
import Snackbar from "./ui-component/Snackbar";

// routing
import Routes from "./routes";

// defaultTheme
import themes from "./themes";
import { useSelector } from "react-redux";

const App = () => {
  const customization = useSelector((state) => state.customization);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={themes(customization)}>
        <CssBaseline />
        <Locales>
          {/* <NavigationScroll> */}
          <JWTProvider>
            <>
              <Routes />
              <Snackbar />
            </>
          </JWTProvider>
          {/* </NavigationScroll> */}
        </Locales>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
