import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/users`;
// const baseURL = `https://gestion-alerte-api.onrender.com/users`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getAllUsers(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

async function getUsersWithRole(role) {
  return axios
    .get(`${baseURL}/role`, {
      params: { role: role },
    })
    .then(HandleResponse)
    .catch(HandleError);
}

// saving
async function createUser(user) {
  return axios
    .post(`${baseURL}/add`, user)
    .then(HandleResponse)
    .catch(HandleError);
}

// editing
async function editUser(user) {
  return axios
    .patch(`${baseURL}/updateUser/`, user)
    .then(HandleResponse)
    .catch(HandleError);
}
// editing
async function editUserPassword(user) {
  return axios
    .patch(`${baseURL}/updateUserPassword/${user._id}`, user)
    .then(HandleResponse)
    .catch(HandleError);
}

// editing status
async function changeStatus(user) {
  return axios
    .patch(`${baseURL}/updateStatus/`, user)
    .then(HandleResponse)
    .catch(HandleError);
}

const userApi = {
  getAllUsers,
  createUser,
  editUser,
  editUserPassword,
  changeStatus,
  getUsersWithRole,
};

export default userApi;
