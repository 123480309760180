// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconPackage,
  IconPackages,
  IconClipboard,
  IconSettings,
  IconSitemap,
  IconFileInvoice,
  IconUsers,
  IconAlertTriangle,
  IconCircle,
  IconCircleDot,
} from "@tabler/icons";

// constant
const icons = {
  IconDashboard,
  IconPackage,
  IconPackages,
  IconClipboard,
  IconSettings,
  IconSitemap,
  IconFileInvoice,
  IconUsers,
  IconAlertTriangle,
  IconCircle,
  IconCircleDot,
};

// ==============================|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||============================== //

const config = {
  id: "sample-docs-roadmap",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: <FormattedMessage id="dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "configuraton",
      title: <FormattedMessage id="configuraton" />,
      type: "collapse",
      icon: icons.IconSettings,
      children: [
        {
          id: "province",
          title: <FormattedMessage id="provinces" />,
          type: "item",
          url: "/provinces",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "commune",
          title: <FormattedMessage id="communes" />,
          type: "item",
          url: "/communes",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "zone",
          title: <FormattedMessage id="zones" />,
          type: "item",
          url: "/zones",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "type-alert",
          title: <FormattedMessage id="type-alert" />,
          type: "item",
          url: "/types_alert",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "alert",
      title: <FormattedMessage id="alerts" />,
      type: "collapse",
      icon: icons.IconAlertTriangle,
      children: [
        {
          id: "nv-alert",
          title: <FormattedMessage id="nv-alert" />,
          type: "item",
          url: "/alerts",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "alerts_management",
          title: <FormattedMessage id="alerts_management" />,
          type: "item",
          url: "/alerts_management",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "report",
      title: <FormattedMessage id="report" />,
      type: "collapse",
      icon: icons.IconSitemap,
      children: [
        // {
        //   id: "recu",
        //   title: <FormattedMessage id="alert-recu" />,
        //   type: "item",
        //   url: "/alertes-recus",
        //   icon: icons.IconPackage,
        //   breadcrumbs: false,
        // },
        // {
        //   id: "traite",
        //   title: <FormattedMessage id="alert-traiter" />,
        //   type: "item",
        //   url: "/alertes-traitees",
        //   icon: icons.IconPackage,
        //   breadcrumbs: false,
        // },
        // {
        //   id: "rejete",
        //   title: <FormattedMessage id="alert-rejeter" />,
        //   type: "item",
        //   url: "/alertes-rejetees",
        //   icon: icons.IconPackage,
        //   breadcrumbs: false,
        // },
      ],
    },
    {
      id: "user-management",
      title: <FormattedMessage id="user-management" />,
      type: "collapse",
      icon: icons.IconUsers,
      children: [
        {
          id: "system-users",
          title: <FormattedMessage id="system-users" />,
          type: "item",
          url: "/users",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "/assistances",
          title: <FormattedMessage id="assistances" />,
          type: "item",
          url: "/assistances",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
        {
          id: "client-user",
          title: <FormattedMessage id="client-user" />,
          type: "item",
          url: "/client_users",
          icon: icons.IconCircle,
          breadcrumbs: false,
        },
      ],
    },

    // {
    //     id: 'subscription-plan',
    //     title: <FormattedMessage id="subscription-plan" />,
    //     type: 'item',
    //     url: '/subscription_plans',
    //     icon: icons.IconClipboard,
    //     breadcrumbs: false
    // }
  ],
};

export default config;
