import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/zones/`;
// const baseURL = `https://gestion-alerte-api.onrender.com/zones/`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getZones(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// saving
async function saveZone(_zone) {
  return axios.post(baseURL, _zone).then(HandleResponse).catch(HandleError);
}

// editing
async function editZone(_zone) {
  return axios
    .patch(`${baseURL}${_zone._id}`, _zone)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteZone(id) {
  return axios
    .delete(`${baseURL}${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const zoneApi = {
  getZones,
  saveZone,
  editZone,
  deleteZone,
};

export default zoneApi;
