import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/clients`;
// const baseURL = `https://gestion-alerte-api.onrender.com/clients`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getAllClients(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// editing status
async function changeStatus(user) {
  return axios
    .patch(`${baseURL}/updateStatus/`, user)
    .then(HandleResponse)
    .catch(HandleError);
}

const clientApi = {
  getAllClients,
  changeStatus,
};

export default clientApi;
