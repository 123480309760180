import { Link } from "react-router-dom";

// material-ui
import { ButtonBase } from "@mui/material";

// project imports
import config from "../../../config";
// import Logo from 'ui-component/Logo';
import logo from "../../../assets/images/WFA.png";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <ButtonBase disableRipple component={Link} to={config.defaultPath}>
    {/* <img src="http://www.africa2trust.com/wba/Logos/ASR27884374.gif" style={{ width: 50 }} alt="" /> */}
    <img src={logo} style={{ width: 80 }} alt="" />
  </ButtonBase>
);

export default LogoSection;
