import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/alertes`;
// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getAlerts(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}
async function getAlertsByStatus(data) {
  return axios
    .get(`${baseURL}/status/${data.status}`, { params: data.date })
    .then(HandleResponse)
    .catch(HandleError);
}
async function getAlertsStatus(data) {
  return axios
    .get(`${baseURL}/status/`, { params: data })
    .then(HandleResponse)
    .catch(HandleError);
}

// saving
async function postAlert(_alert) {
  return axios.post(baseURL, _alert).then(HandleResponse).catch(HandleError);
}

// editing
async function editAlert(_alert) {
  return axios
    .patch(`${baseURL}/${_alert._id}`, _alert)
    .then(HandleResponse)
    .catch(HandleError);
}
// transfering alert
async function transferAlert(_alert) {
  return axios
    .patch(`${baseURL}/transfer/${_alert.id}`, _alert)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteAlert(id) {
  return axios
    .delete(`${baseURL}/${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const alertApi = {
  getAlerts,
  postAlert,
  editAlert,
  deleteAlert,
  getAlertsStatus,
  transferAlert,
  getAlertsByStatus,
};

export default alertApi;
