import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/static`;
// const baseURL = `https://gestion-alerte-api.onrender.com/static`;

async function getAllRoles(params) {
  return axios.get(`${baseURL}/roles`).then(HandleResponse).catch(HandleError);
}

const staticApi = {
  getAllRoles,
};

export default staticApi;
