import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/charts`;
// const baseURL = `https://gestion-alerte-api.onrender.com/charts`;

async function getAllMalesFemalesData() {
  return axios
    .get(`${baseURL}/malesfemales`)
    .then(HandleResponse)
    .catch(HandleError);
}

const chartsApi = {
  getAllMalesFemalesData,
};

export default chartsApi;
