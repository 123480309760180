import { lazy } from "react";

// project imports
import MainLayout from "../layout/MainLayout";
import Loadable from "../ui-component/Loadable";
import AuthGuard from "../utils/route-guard/AuthGuard";

// sample page routing

// routes
const Alert = Loadable(lazy(() => import("../views/pages/client/alert")));
const Dashboard = Loadable(
  lazy(() => import("../views/pages/client/dashboard"))
);

// =================================================================
const Province = Loadable(lazy(() => import("../views/pages/config/province")));
const Commune = Loadable(lazy(() => import("../views/pages/config/commune")));
const Zone = Loadable(lazy(() => import("../views/pages/config/zone")));
const Type = Loadable(lazy(() => import("../views/pages/config/typeAlert")));
const User = Loadable(lazy(() => import("../views/pages/config/user")));
const Client = Loadable(lazy(() => import("../views/pages/config/client")));
const Assistance = Loadable(
  lazy(() => import("../views/pages/config/assistance"))
);
const Management = Loadable(
  lazy(() => import("../views/pages/client/management"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "/dashboard",
      element: <Dashboard />,
    },
    {
      path: "/provinces",
      element: <Province />,
    },
    {
      path: "/communes",
      element: <Commune />,
    },
    {
      path: "/zones",
      element: <Zone />,
    },
    {
      path: "/types_alert",
      element: <Type />,
    },
    {
      path: "/assistances",
      element: <Assistance />,
    },
    {
      path: "/alerts",
      element: <Alert />,
    },
    {
      path: "/users",
      element: <User />,
    },
    {
      path: "/client_users",
      element: <Client />,
    },
    {
      path: "/alerts_management",
      element: <Management />,
    },
  ],
};

export default MainRoutes;
