import config from "./config";
// import client from "./client";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [config],
};

export default menuItems;
