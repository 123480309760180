import axios from "axios";
import { HandleError, HandleResponse } from "./apiUtils";

const baseURL = `${process.env.REACT_APP_URL}/api/v1/typealertes/`;
// const baseURL = `https://gestion-alerte-api.onrender.com/typealertes/`;

// , {
//     params: {
//         sort: params ? `code,${params.sort}` : '',
//         title: params ? params.title : '',
//         action: params ? params.action : '',
//         page: params ? params.page : null,
//         size: params ? params.size : null
//     }
// }

async function getTypeAlert(params) {
  return axios.get(baseURL).then(HandleResponse).catch(HandleError);
}

// saving
async function saveTypeAlert(type) {
  return axios.post(baseURL, type).then(HandleResponse).catch(HandleError);
}

// editing
async function editTypeAlert(type) {
  return axios
    .patch(`${baseURL}${type._id}`, type)
    .then(HandleResponse)
    .catch(HandleError);
}

// deleting
async function deleteTypeAlert(id) {
  return axios
    .delete(`${baseURL}${id}`)
    .then(HandleResponse)
    .catch(HandleError);
}

const typeAlertApi = {
  getTypeAlert,
  saveTypeAlert,
  editTypeAlert,
  deleteTypeAlert,
};

export default typeAlertApi;
